import React, { useState, useEffect, useRef } from "react";
import Layout from "../../components/Layout";
import SwitchButton from "../../components/SwitchButton/SwitchButton";
import Map from "../../components/Map/Map";
import { getMapData } from '../../reducers/Map/actions'
import { setServiceType, setDistrict, setCity } from '../../reducers/Home/actions'
import { filterService, resetCurrentPage } from "../../reducers/Services/actions";
import { scrollToElement } from '../../utils/util'
import { Row, Col } from "reactstrap";
import { navigate } from "gatsby";
import { connect } from 'react-redux'
import { urlDescription } from '../../utils/pageDescription'
import "./styles.scss";

const MapPage = ({
  data,
  getMapData,
  serviceType,
  setServiceType,
  districtId,
  setDistrict,
  setCity,
  page,
  filterService,
  resetCurrentPage
}) => {
  const [isMobile, setIsMobile] = useState(false);
  const [message, setMessage] = useState('')
  const messageContainer = useRef(null)

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    getMapData();
  }, [getMapData])

  useEffect(() => {
    if (serviceType && serviceType !== "" && districtId) {
      filterService({
        page,
        districtId: districtId && districtId.id,
        service: serviceType
      })
    }
  }, [filterService, districtId, serviceType, page])

  let url = "";

  if (typeof window !== "undefined") {
    url = new URL(window.location.href);
  }

  return (
    <Layout description={urlDescription[url.pathname]}>
      <div className="map-page-wrapper">
        <div className="map-page-container">
          <div className="projects-page-title">
            <h1 className="h2 font-weight-bold">Търсене по области</h1>
          </div>
          <section className="projects-btn-section">
            <Row className='map-radio-row justify-content-center'>
              <Col sm={12} md={5} lg={4} className='map-radio-col'>
                <SwitchButton
                  switchSize="switch-size"
                  title="Архитектурно бюро"
                  checked={serviceType === "architects"}
                  onClick={() => {
                    setServiceType({ type: "architects" })
                    setMessage('')
                  }}
                  onChange={() => {
                    resetCurrentPage();
                    setServiceType({ type: "architects" });
                    setMessage('')
                  }}
                />
              </Col>
              <Col sm={12} md={5} lg={4} className='map-radio-col'>
                <SwitchButton
                  switchSize="switch-size"
                  title="Строителна компания"
                  checked={serviceType === "builders"}
                  onClick={() => {
                    setMessage('')
                    setServiceType({ type: "builders" })
                  }}
                  onChange={() => {
                    resetCurrentPage();
                    setServiceType({ type: "builders" });
                    setMessage('')
                  }}
                />
              </Col>
              <Col sm={12} md={5} lg={4} className='map-radio-col'>
                <SwitchButton
                  switchSize="switch-size"
                  title="Дистрибутори"
                  checked={serviceType === "dealers"}
                  onClick={() => {
                    setServiceType({ type: "dealers" })
                    setMessage('')
                  }}
                  onChange={() => {
                    resetCurrentPage();
                    setServiceType({ type: "dealers" });
                    setMessage('')
                  }}
                />
              </Col>
            </Row>
          </section>
          <div ref={messageContainer} className='warning-message font-weight-bold'>
            {message !== '' ? message : null}
          </div>
          <Row>
            <Col>
              <div className="margin-top5 map-container mx-auto mt-5">
                <Map
                  isMobile={isMobile}
                  onClickChange={(name) => {
                    if (serviceType && serviceType !== '') {
                      setMessage('')
                    }
                    let city = data.filter(c => c.name === name)
                    setDistrict(city[0])
                    if (name ==='София') {
                      setDistrict({id:24, name: 'София-град'})  
                    }
                    if (name === 'София-област') {
                      setDistrict({id:23, name: 'София'})
                    }
                    setCity(null)
                    if (serviceType && serviceType !== '') {
                      navigate(`/services?service=${serviceType}`)
                    } else {
                      scrollToElement(messageContainer)
                      setMessage('Моля изберете вид услуга!')
                    }
                  }}
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.map.data,
    districtId: state.home.districtId,
    serviceType: state.home.serviceType,
    page: state.services.current_page,
  };
};

const mapDispatchToProps = {
  getMapData,
  setServiceType,
  setDistrict,
  setCity,
  filterService,
  resetCurrentPage
};

export default connect(mapStateToProps, mapDispatchToProps)(MapPage);
